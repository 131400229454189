import { useEffect, useState } from "react";
import { client } from "../helpers/contentfulClient";

export const useGetWebinars = () => {
  const [webinars, setWebinars] = useState([]);
  const [pastWebinars, setPastWebinars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "webinar",
        });

        if (entries.items && entries.items.length >= 1) {
          const currentDate = new Date();

          const filteredEntries = entries.items.sort((a, b) => {
            const dateA = new Date(a.fields.webinarDate);
            const dateB = new Date(b.fields.webinarDate);
            return dateA - dateB; // Sort in ascending order (closest date first)
          });

          const upcomingWebinars = filteredEntries.filter((entry) => {
            const webinarDate = new Date(entry.fields.webinarDate);
            return webinarDate >= currentDate;
          });

          const pastWebinars = filteredEntries.filter((entry) => {
            const webinarDate = new Date(entry.fields.webinarDate);
            return webinarDate < currentDate;
          });

          // For past webinars, reverse the order to have the most recent date first
          const reversedPastWebinars = pastWebinars.slice().reverse();

          setWebinars(upcomingWebinars);
          setPastWebinars(reversedPastWebinars);
        } else {
          // If no entries are found, set webinars and pastWebinars to empty arrays
          setWebinars([]);
          setPastWebinars([]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { webinars, pastWebinars, loading, error };
};
