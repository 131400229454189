import { Grid, Typography, Container, useTheme } from "@mui/material";

export default function Infographics({ title, config }) {
  const theme = useTheme();
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={12} className="flex justify-center align-center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
            {title}
          </Typography>
        </Grid>
        {config &&
          config.map((item, index) => (
            <Grid
              item
              md={6}
              className="flex align-center"
              sx={{ mt: 5 }}
              key={index}
            >
              {item.icon}
              <Typography
                variant="h2"
                sx={{ ml: 3, color: theme.palette.primary.main }}
              >
                {item.number}
              </Typography>
              <Typography variant="h6" sx={{ ml: 3 }}>
                {item.text}
              </Typography>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
