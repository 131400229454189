import LayersIcon from "@mui/icons-material/Layers";
import TimerIcon from "@mui/icons-material/Timer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";

const programNumbers = [
  {
    number: "15",
    icon: <LayersIcon style={{ fontSize: "60px", color: "#032d3a" }} />,
    text: "The number of learning modules included in the program",
  },
  {
    number: "6",
    icon: <CalendarMonthIcon style={{ fontSize: "60px", color: "#032d3a" }} />,
    text: "The duration of months afforded to complete the program",
  },
  {
    number: "~20",
    icon: <TimerIcon style={{ fontSize: "60px", color: "#032d3a" }} />,
    text: "The average number of hours it takes to complete the program",
  },
  {
    number: "1",
    icon: <StarIcon style={{ fontSize: "70px", color: "#032d3a" }} />,
    text: "The number of clicks required to elevate your career",
  },
];

export default programNumbers;
