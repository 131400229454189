export const formatWebinarDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "America/New_York", // Update with the correct time zone
  };

  const formattedDate = new Date(dateString).toLocaleString("en-US", options);
  return formattedDate;
};
