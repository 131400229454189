import { Box, Typography, Button, Menu, MenuItem } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Fade from "@mui/material/Fade";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CNMAPInfoMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (path) => {
    setAnchorEl(null);
    navigate(path);
  };

  const menuItems = [
    {
      title: "What is CNMAP™?",
      path: "/cnmap-program-information",
    },
    {
      title: "Enrollment Criteria",
      path: "/cnmap-enrollment-criteria",
    },
    {
      title: "Payment Information",
      path: "/cnmap-payment",
    },
    {
      title: "Promote Your Certification",
      path: "/cnmap-promote-certification",
    },
    {
      title: "FAQs",
      path: "/cnmap-faqs",
    },
    {
      title: "Steering Committee ",
      path: "/steering-committee",
    },
    {
      title: "Recertification",
      path: "/cnmap-recertification",
    },
  ];
  return (
    <Box>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ mr: 1, fontSize: "15px" }}
      >
        CNMAP Information
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuItems &&
          menuItems.length >= 1 &&
          menuItems.map((item, index) => (
            <MenuItem key={index} onClick={() => handleSelect(item.path)}>
              <Box
                className="flex justify-center align-center"
                sx={{ pt: 1, pb: 1 }}
              >
                <ChevronRightIcon />
                <Typography>{item.title}</Typography>
              </Box>
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}
