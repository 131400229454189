import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import Subnav from "../../components/subnav/index";
import PromoteInfo from "./helpers/PromoteInfo";
import DocumentPolicy from "../../components/ui/DocumentPolicy";
import Footer from "../../components/ui/Footer";

export default function Promote() {
  return (
    <>
      <Helmet>
        <title>Promote CNMAP™</title>
        <meta
          name="description"
          content="This is a brief summary of my page content."
        />
        <link rel="canonical" href="https://example.com/my-page" />
        <meta property="og:title" content="My Page Title" />
        <meta
          property="og:description"
          content="This is a brief summary of my page content."
        />
        <meta
          property="og:image"
          content="https://example.com/my-page-image.jpg"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader
            mainTitle={"Certification Promotion"}
            image="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/certificate.svg"
            width={"70%"}
          />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <Box
        sx={{
          pt: 12,
          pb: 12,
        }}
      >
        <PromoteInfo />
      </Box>

      <Container>
        <Box
          sx={{
            pt: 12,
            pb: 12,
          }}
        >
          <DocumentPolicy />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
