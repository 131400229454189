import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import Spinner from "../../components/ui/Spinner";
import Footer from "../../components/ui/Footer";
import { useGetWebinars } from "../../hooks/useGetWebinars";
import WebinarHeader from "./helpers/WebinarHeader";
import UpcomingWebinars from "./helpers/UpcomingWebinars";
import PastWebinars from "./helpers/PastWebinars";

export default function Webinars() {
  const { webinars, pastWebinars, loading, error } = useGetWebinars();

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Container sx={{ mt: 5 }}>
        <Box>
          <Card>
            <CardContent>
              <Typography variant="h3">
                Whoops, unable to load Webinars
              </Typography>
              <Typography>
                Please refresh the page and try again later!
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Box sx={{ mt: 3 }}>
          <WebinarHeader />
        </Box>
        <Box sx={{ mt: 4 }}>
          <UpcomingWebinars webinars={webinars} />
        </Box>

        <Box sx={{ mt: 6 }}>
          <Divider />
        </Box>

        <Box sx={{ mt: 4 }}>
          <PastWebinars pastWebinars={pastWebinars} />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
