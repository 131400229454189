import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedIcon from "@mui/icons-material/Verified";
import StarIcon from "@mui/icons-material/Star";
import PaidIcon from "@mui/icons-material/Paid";
import PolicyIcon from "@mui/icons-material/Policy";

const navConfig = [
  {
    title: "home",
    path: "/home",
    icon: <HomeIcon />,
  },
  {
    title: "CNMAP™ Info",
    path: "/cnmap-program-information",
    icon: <InfoIcon />,
  },
  {
    title: "Enrollment Info",
    path: "/cnmap-enrollment-criteria",
    icon: <VerifiedIcon />,
  },

  {
    title: "Payment",
    path: "/cnmap-payment",
    icon: <PaidIcon />,
  },
  {
    title: "Recertification",
    path: "/cnmap-recertification",
    icon: <StarIcon />,
  },
  {
    title: "FAQs",
    path: "/cnmap-faqs",
    icon: <StarIcon />,
  },
  {
    title: "Promote",
    path: "/cnmap-promote-certification",
    icon: <PolicyIcon />,
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    icon: <LiveHelpIcon />,
  },
];

export default navConfig;
