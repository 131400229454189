const ContentList = [
  {
    title: "Career progression for nurses with clinical experience",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/nurse.png",
  },
  {
    title:
      "Get in on the opportunity to power your career in a rapidly growing field",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/growth.png",
  },
  {
    title: "Increase your earning potential",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/earnings.png",
  },
  {
    title:
      "Opportunity for nurses looking for a career that can offer flexibility in their work environment",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/jigsaw.png",
  },
];

export default ContentList;
