import {
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function PromoteInfo() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Share Your Achievement
        </Typography>
        <Typography sx={{ mt: 3, mb: 3 }} variant="h6">
          Becoming a Certified Nurse Medical Affairs Professional is a major
          accomplishment. We encourage you to share your achievement with your
          professional community.
        </Typography>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Tips
        </Typography>

        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Let your company know what you’ve accomplished." />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Share your achievement on Linkedin and add the ’CNMAP’ designation to your professional headline and title." />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Once news of your certification appears, share posts and links with social media (Instagram, Linkedin, and Facebook) and hashtag #CNMAP" />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
}
