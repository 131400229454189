// Icons

import ArticleIcon from "@mui/icons-material/Article";
import DuoIcon from "@mui/icons-material/Duo";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
// ----------------------------------------------------------------------

const resourcesConfig = [
  {
    title: "Blogs",
    path: "/blogs",
    icon: <ArticleIcon />,
  },
  {
    title: "Webinars",
    path: "/webinars",
    icon: <DuoIcon />,
  },
  {
    title: "Scholarships",
    path: "/scholarships",
    icon: <AutoGraphIcon />,
  },
];

export default resourcesConfig;
