import { Box, Button, Grid, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import useResponsive from "../../hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { openInNewTab } from "../../helpers/openInNewTab";
import { cnmapMarketplace } from "../../constants/buttons";

export default function InnerPageHeader({
  description,
  mainTitle,
  title,
  image,
  width,
  learnMoreBtn,
}) {
  const isDesktop = useResponsive("up", "md");
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item md={6} className="flex justify-center column">
        <Typography variant="h2">
          {mainTitle || " Certified Nurse Medical Affairs Professional"}

          {title ? (
            <>
              {" "}
              <span
                style={{
                  textDecorationLine: "underline",
                  textDecorationStyle: "dotted",
                  textDecorationColor: "#023e51",
                }}
              >
                {title}
              </span>
            </>
          ) : (
            <></>
          )}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          {description ||
            "Discover the path to becoming a Certified Nurse Medical Affairs Professional through our comprehensive program."}
        </Typography>
        {learnMoreBtn !== "false" ? (
          <>
            {" "}
            <Box sx={{ mt: 4 }} className="flex">
              <Button
                variant="outlined"
                size="large"
                sx={{ width: !isDesktop ? "100%" : "50%" }}
                onClick={(e) => navigate("/cnmap-program-information")}
              >
                {isDesktop ? "Learn More About CNMAP™" : "Learn More"}
              </Button>
              <Button
                sx={{ ml: 2, width: !isDesktop ? "100%" : "50%" }}
                size="large"
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() => openInNewTab(cnmapMarketplace)}
              >
                {isDesktop ? "Get Started Today" : "Get Started"}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ mt: 4 }} className="flex">
              <Button
                size="large"
                variant="contained"
                endIcon={<SendIcon />}
                sx={{ width: !isDesktop ? "100%" : "50%" }}
                onClick={() => openInNewTab(cnmapMarketplace)}
              >
                {isDesktop ? "Get Started Today" : "Get Started"}
              </Button>
            </Box>
          </>
        )}
      </Grid>
      <Grid item md={6} className="flex justify-center">
        <img
          src={
            image && image.length >= 1
              ? image
              : "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/Nursing+cert+badge+seal.svg"
          }
          alt="cnmap badge"
          width={width || "70%"}
        />
      </Grid>
    </Grid>
  );
}
