import { Container } from "@mui/material";

import navConfig from "./config";
import NavSection from "./Nav";
import useResponsive from "../../hooks/useResponsive";
import NavSectionMobile from "../subnavMobile";

export default function SubnavV2() {
  const isDesktop = useResponsive("up", "md");
  return (
    <Container sx={{ pt: 2, pb: 2 }}>
      {isDesktop ? (
        <>
          <NavSection data={navConfig} />
        </>
      ) : (
        <>
          <NavSectionMobile data={navConfig} />
        </>
      )}
    </Container>
  );
}
