import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { openInNewTab } from "../../helpers/openInNewTab";
import { cnmapMarketplace } from "../../constants/buttons";

export default function Pathway() {
  const theme = useTheme();
  return (
    <Container sx={{ pt: 5, pb: 5 }}>
      <Typography
        variant="h2"
        sx={{ color: theme.palette.primary.main }}
        className="text-center"
      >
        Establish Your Pathway into the Life Sciences Industry Now!
      </Typography>
      <Grid container spacing={4}>
        <Grid item md={4} sx={{ mt: 3 }} container>
          <Card>
            <CardContent>
              <Box className="flex align-center">
                <img
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/angela+j+francis.jpg"
                  alt="Angela Francis"
                  style={{ borderRadius: "20px", width: "10%" }}
                />
                <Box>
                  <Typography variant="h6" sx={{ ml: 2, fontWeight: "bold" }}>
                    Angela J Francis RN, BSN, CCM
                  </Typography>
                  <Typography sx={{ ml: 2 }}>Case Management Nurse</Typography>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "14px", mt: 2 }}>
                  I highly recommend The Certified Nurse Medical Affairs
                  Professional Program.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    This comprehensive program utilized multimodal learning that
                    kept me engaged and well prepared me to take the final exam
                    and successfully obtain my certification.
                  </span>
                  Prior to this course I had heard of the word “biopharma” and
                  knew that nurses could work in clinical research, but I was
                  completely unaware that there was a life sciences industry
                  with lots of opportunities where I can use my nursing clinical
                  knowledge and critical thinking skills.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    This certification gives me the confidence to apply for jobs
                    in this industry that I didn't know existed and would not
                    have thought I would be qualified to do.
                  </span>
                </Typography>
              </Box>

              {/* <Grid spacing={3} container>
                <Grid
                  item
                  md={3}
                  className="flex justify-center align-center column"
                >
                  <img
                    src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/angela+j+francis.jpg"
                    alt="Angela Francis"
                    style={{ borderRadius: "20px", width: "50%" }}
                  />
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ mt: 2, fontWeight: "bold" }}
                      className="text-center"
                    >
                      Angela J Francis RN, BSN, CCM
                    </Typography>
                    <Typography className="text-center">
                      Case Management Nurse
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={9}>
                  <Typography sx={{ fontSize: "18px" }}>
                    I highly recommend The Certified Nurse Medical Affairs
                    Professional Program. This comprehensive program utilized
                    multimodal learning that kept me engaged and well prepared
                    me to take the final exam and successfully obtain my
                    certification. Prior to this course I had heard of the word
                    “biopharma” and knew that nurses could work in clinical
                    research, but I was completely unaware that there was a life
                    sciences industry with lots of opportunities where I can use
                    my nursing clinical knowledge and critical thinking skills.
                    This certification gives me the confidence to apply for jobs
                    in this industry that I didn't know existed and would not
                    have thought I would be qualified to do.
                  </Typography>
                </Grid>
              </Grid> */}
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} sx={{ mt: 3 }} container>
          <Card>
            <CardContent>
              <Box className="flex align-center">
                <img
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/Scott+Faust.jpg"
                  alt="Scott Faust"
                  style={{ borderRadius: "20px", width: "10%" }}
                />
                <Box>
                  <Typography variant="h6" sx={{ ml: 2, fontWeight: "bold" }}>
                    Scott Faust CEN, PHN, CNMAP
                  </Typography>
                  <Typography sx={{ ml: 2 }}>Principal Owner</Typography>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "14px", mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>
                    I found the CNMAP program to be comprehensive yet engaging
                    and interesting
                  </span>
                  . This program is perfect for the nurse who is seeking to
                  transition from bedside to the corporate world of medical
                  affairs . The critical thinking required of us in the clinical
                  world translates well to industry, and this program helps
                  expand our critical thinking skill set. by building on our
                  existing knowledge and introducing key topics that have
                  traditionally not been taught in school. Knowledge is power
                  and this program provides the knowledge necessary to be able
                  to communicate with hiring managers, key stakeholders, and
                  others.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    More importantly this program equips the nurse with required
                    skills to be successful in transitioning to industry.
                  </span>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={4} sx={{ mt: 3 }} container>
          <Card>
            <CardContent>
              <Box className="flex align-center">
                <img
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/Shannon+Lunn.jpg"
                  alt="Shannon Lunn"
                  style={{ borderRadius: "20px", width: "10%" }}
                />
                <Box>
                  <Typography variant="h6" sx={{ ml: 2, fontWeight: "bold" }}>
                    Shannon Lunn CRN-BC, CNMAP
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    Clinical Research Coordinator Nurse III
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "14px", mt: 2 }}>
                  The Certified Nurse Medical Affairs Professional certification
                  program exceeded my expectations! Having previously taken
                  medical industry courses at a university,{" "}
                  <span style={{ fontWeight: "bold" }}>
                    I was pleasantly surprised by the depth of knowledge this
                    program offered that went well beyond my academic
                    coursework.
                  </span>{" "}
                  As a clinical research nurse, it provided invaluable insights
                  into the intersection of the life science industry with
                  academic research and hospitals.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    This program equipped me with a comprehensive understanding
                    of the intricate dynamics involved in obtaining approvals
                    for drugs or devices.{" "}
                  </span>
                  I wholeheartedly recommend this program to any nurse involved
                  in or aspiring to enter the life sciences field.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} sx={{ mt: 1 }} container>
          <Card>
            <CardContent>
              <Box className="flex align-center">
                <img
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/Kathryn+Ciccolini+DNP%2C+AGACNP-BC%2C+OCN%2C+CNMAP.jpg"
                  alt="Kathryn Ciccolini DNP, AGACNP-BC, OCN, CNMAP"
                  style={{ borderRadius: "20px", width: "10%" }}
                />
                <Box>
                  <Typography variant="h6" sx={{ ml: 2, fontWeight: "bold" }}>
                    Kathryn Ciccolini DNP, AGACNP-BC, OCN, CNMAP
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    Cellular Therapy Director Commercial Learning & Development
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "14px", mt: 2 }}>
                  I have officially earned my CNMAP (Certified Nursing Medical
                  Affairs Professional) certification! 🎓✨.{" "}
                  <span style={{ fontWeight: "bold" }}>
                    The course was impressively thorough and engaging,
                    delivering extensive knowledge and valuable insights.
                  </span>{" "}
                  I really appreciate the work that went into creating such a
                  comprehensive program.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 5 }} className="flex justify-center align-center">
        <Button
          variant="contained"
          size="large"
          onClick={() => openInNewTab(cnmapMarketplace)}
        >
          Enroll Today
        </Button>
      </Box>
    </Container>
  );
}
