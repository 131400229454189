import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";

export default function Committee() {
  const committee = [
    {
      name: "Janice Nissen BSN, MBA, MS Population Health",
      title: "Independent consultant",
      image:
        "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/people/Janice.jpg",
      company: "PatientsRPeople, LLC",
    },
    {
      name: "Julia Lanouette BA, MSN, RN",
      title: "Director of Pharmacovigilance Operations",
      image:
        "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/people/Julia.jpeg",
      company: "Moderna",
    },
    {
      name: "Michell Davi",
      title: "Executive Director Patient Insights",
      image:
        "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/people/MDavi+headshot.jpg",
      company: "Merck",
    },
    {
      name: "Jessica Saucier",
      title: "Associate Director Clinical Trial Educators",
      image:
        "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/people/Jessica.jpg",
      company: "IQVIA",
    },
    {
      name: "Margaret Weston",
      title: "Health Care Quality Solutions Director",
      image:
        "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/people/weston.jpg",
      company: "J&J",
    },
  ];

  return (
    <Container>
      <Grid container spacing={3}>
        {committee &&
          committee.length >= 1 &&
          committee.map((item, index) => (
            <Grid item md={3} container key={index}>
              <Card>
                <CardMedia
                  component="img"
                  alt={item.name}
                  image={item.image}
                  sx={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    maxHeight: "250px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <CardContent>
                  <Typography sx={{ fontSize: "15px" }}>
                    <b>{item.name}</b>
                  </Typography>
                  <br />
                  <Typography>
                    {item.title} at {item.company}
                  </Typography>
                  {/* <br />
                  <Typography sx={{ mt: 1 }} variant="">
                    {item.company}
                  </Typography> */}
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
