import {
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function EnrollmentCriteria() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Enrollment Criteria
        </Typography>

        <Typography
          sx={{ mt: 3, mb: 2, color: theme.palette.primary.main }}
          variant="h6"
        >
          In order to be certified, you must be one of the following:
        </Typography>
        {/* <Typography sx={{ lineHeight: "2" }}>
          Nurse or advance practice nurse
        </Typography>

        <Typography sx={{ lineHeight: "2" }}>
          Graduate of relevant degree from an accredited college, university, or
          institution
        </Typography>
        <Typography
          sx={{ mt: 3, mb: 2, color: theme.palette.primary.main }}
          variant="h6"
        >
          Or
        </Typography>
        <Typography sx={{ lineHeight: "2" }}>RN, BSN, MSN, DNP, etc</Typography>
        <Typography sx={{ mt: 3, mb: 2, lineHeight: "2" }}>
          ACMA Certification is conferred to professionals that have met
          rigorous standards through intensive study, self-assessment and
          evaluation. The CNMAP™ certification is the most comprehensive medical
          affairs professional development and training program for nurses in
          the world. Achieving the CNMAP™ demonstrates the highest standards of
          knowledge and excellence in the profession. It also sets the stage for
          continual professional development through values centered on lifelong
          learning.
        </Typography> */}
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Nurse or advance practice nurse" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Graduate of relevant degree from an accredited college, university, or institution" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="RN, BSN, MSN, DNP, etc" />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
}
