import { Box, Container, Typography } from "@mui/material";
import Subnav from "../../components/subnav/index";
import HubspotForm from "react-hubspot-form";

import { Helmet } from "react-helmet-async";
import InnerPageHeader from "../../components/ui/InnerPageHeader";

export default function ContactUs() {
  return (
    <>
      <Helmet>
        <title>FAQs</title>
        <meta
          name="description"
          content="This is a brief summary of my page content."
        />
        <link rel="canonical" href="https://example.com/my-page" />
        <meta property="og:title" content="My Page Title" />
        <meta
          property="og:description"
          content="This is a brief summary of my page content."
        />
        <meta
          property="og:image"
          content="https://example.com/my-page-image.jpg"
        />
        <script
          type="text/javascript"
          data-cfasync="false"
        >{`window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } }; (function () { var done = false; var script = document.createElement('script'); script.async = true; script.type = 'text/javascript'; script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; document.getElementsByTagName('HEAD').item(0).appendChild(script); script.onreadystatechange = script.onload = function (e) { if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { var w = new PCWidget({c: '9ebe7140-f8dd-461f-8166-bc15f0f62aca', f: true }); done = true; } }; })();`}</script>
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader
            mainTitle={"How can we help?"}
            image="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/contact-us.svg"
            width={"100%"}
            description={
              " Reach out to us anytime, anywhere. Our dedicated team is here for you 24/7, providing quick and responsive support. Your questions and inquiries are our top priority."
            }
          />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <script
        charset="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/embed/v2.js"
      ></script>

      <Box sx={{ pt: 15, pb: 15 }}>
        <Container>
          <Typography variant="h3" sx={{ textAlign: "center" }}>
            Contact Us
          </Typography>
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            Our dedicated team is here for you 24/7, providing quick and
            responsive support.{" "}
          </Typography>
          <Box sx={{ pt: 8 }}>
            <HubspotForm
              portalId="22711473"
              formId="f68cd668-da87-462a-adfd-c28beb7d595f"
              onSubmit={() => console.log("Submit!")}
              onReady={(form) => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}
