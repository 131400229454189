import { useEffect, useState } from "react";
import { client } from "../helpers/contentfulClient";

export const useGetBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "blogPost",
        });
        if (entries.items && entries.items.length >= 1) {
          const filteredEntries = entries.items.sort((a, b) => {
            const dateA = new Date(a.sys.createdAt);
            const dateB = new Date(b.sys.createdAt);
            return dateB - dateA;
          });
          setBlogs(filteredEntries);
        } else {
          // If no entries are found, set blogs to an empty array
          setBlogs([]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { blogs, loading, error };
};
