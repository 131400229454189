const RecertList = [
  {
    title: "Commitment of Excellence",
    subTitle:
      "Continue to demonstrate your commitment of excellence and commitment to high standards in the industry",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/growth.png",
  },
  {
    title: "Keep Up With Standards",
    subTitle:
      "Keep up with life sciences industry standards and current protocols",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/check-badge.svg",
  },
  {
    title: "Boost Your Career Options",
    subTitle: "Take the next step forward to expand your career possibilities",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/Options.svg",
  },
];

export default RecertList;
