import { Box, Container, Typography, useTheme } from "@mui/material";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import { Helmet } from "react-helmet-async";
import Subnav from "../../components/subnav/index";
import RecertificationInfo from "../../components/ui/RecertificationInfo";
import WhyCertify from "../../components/ui/WhyCertify";
import RecertList from "../../components/ui/WhyCertify/RecertList";
import RecertBenefits from "../../components/ui/WhyCertify/RecertBenefits";
import RecertificationInfoSteps from "../../components/ui/RecertificationInfoSteps";
import DocumentPolicy from "../../components/ui/DocumentPolicy";
import Footer from "../../components/ui/Footer";
// import { recertificationList } from "../../components/ui/WhyCertify/config";

export default function Recertification() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>Recertification</title>
        <meta
          name="description"
          content="This is a brief summary of my page content."
        />
        <link rel="canonical" href="https://example.com/my-page" />
        <meta property="og:title" content="My Page Title" />
        <meta
          property="og:description"
          content="This is a brief summary of my page content."
        />
        <meta
          property="og:image"
          content="https://example.com/my-page-image.jpg"
        />
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader mainTitle={"Recertification"} width={"70%"} />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      {/* <Box
        sx={{
          pt: 12,
          pb: 12,
        }}
      >
        <WhyCertify list={RecertList} title={"Why should I recertify?"} />
      </Box> */}

      <Box
        sx={{
          pt: 12,
          pb: 15,
          backgroundColor: "white",
        }}
      >
        <RecertificationInfo />
      </Box>

      <Box
        sx={{
          pt: 12,
          pb: 12,
        }}
      >
        <WhyCertify
          list={RecertBenefits}
          title={"What are the benefits of maintaining my certification?"}
        />
      </Box>

      <Box
        sx={{
          pt: 12,
          pb: 15,
          backgroundColor: "white",
        }}
      >
        <RecertificationInfoSteps />
      </Box>
      <Container>
        <Box
          sx={{
            pt: 15,
            pb: 15,
          }}
        >
          <DocumentPolicy />
        </Box>
      </Container>

      <Footer />
    </>
  );
}
