import { Backdrop } from "@mui/material";
import { useState } from "react";
import { BarLoader } from "react-spinners";

export default function Spinner() {
  const [open, setOpen] = useState(true);

  return (
    <Backdrop
      sx={{
        color: "black",
        backgroundColor: "white",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <BarLoader color="black" width={200} />
    </Backdrop>
  );
}
