import { Box, Container, Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function CNMAPisFor() {
  const types = [
    "Medical Writing",
    "Medical Information",
    "Medical Science Liaison",
    "Patient Engagement",
    "Medical Education",
    "Outcomes Research",
    "Clinical Trial Operations",
    "Clinical Trial Site Mgmt",
    "Pharmacovigilance",
    "Sales/Acct Mgmt",
    "Marketing",
    "Nurse Educators",
    "Healthcare Quality Solutions",
    "Employee Health Wellness/Services",
    "Health Equity",
  ];
  return (
    <Box sx={{ pt: 15, pb: 15 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="h2" sx={{ color: "white" }}>
              {/* Who is CNMAP™ for? */}
              What roles will I learn about in CNMAP™?
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "white", mb: 7 }}>
              CNMAP™ is for nurses and advanced degree nurses who are interested
              in roles in the life sciences. Nurses will learn about the roles
              listed below, what skills are required for each pathway and what a
              typical day in the life of each position is all about:
            </Typography>
          </Grid>
          {types &&
            types.length >= 1 &&
            types.map((item, index) => (
              <Grid item md={6} xs={12} key={index}>
                <Typography
                  variant="h5"
                  sx={{ color: "white" }}
                  className="flex align-center"
                >
                  <CheckCircleIcon style={{ marginRight: "7px" }} />
                  {item}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
}
