import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import Label from "../../../components/label/Label";
import { formatBlogDate } from "../../../helpers/formatBlogDate";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { formatString } from "../../../helpers/formatString";
import { Link } from "react-router-dom";

export default function AllBlogs({ blogs }) {
  return (
    <Box>
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          All Blogs
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {blogs &&
            blogs.length >= 1 &&
            blogs.map((item, index) => (
              <Grid item md={6} key={index}>
                <Card>
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid
                        item
                        md={4}
                        container
                        className="flex justify-center align-center"
                      >
                        <img
                          src={item.fields.blogImage.fields.file.url}
                          alt={item.fields.blogTitle}
                          style={{ borderRadius: "16px", height: "150px" }}
                          loading="lazy"
                        />
                      </Grid>
                      <Grid item md={8} container>
                        <Box
                          sx={{ width: "100%" }}
                          className="flex justify-between align-center"
                        >
                          <Typography
                            sx={{ fontWeight: 700, fontSize: "14px" }}
                          >
                            {formatBlogDate(item.fields.blogUploadDate)}
                          </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 700, mt: 1 }}>
                          <Link
                            to={`/blog/${item.fields.blogUrlSlug}`}
                            style={{ color: "black" }}
                          >
                            {item.fields.blogTitle}
                          </Link>
                        </Typography>
                        <Box sx={{ mt: 1 }} className="flex align-center">
                          <Typography>
                            {formatString(item.fields.blogUploadedBy, 10)}
                          </Typography>
                          <Typography sx={{ ml: 1, mr: 1 }}>|</Typography>
                          <Label>
                            {item.fields.blogEstimatedReadTime} Minutes Read
                          </Label>
                        </Box>
                        <Typography sx={{ mt: 1 }}>
                          {formatString(
                            documentToReactComponents(
                              item.fields.blogExcerpt
                            )[0].props.children[0],
                            100
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}
