import { Box, Container, Grid, Rating, Typography } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

export default function BecomeCertified() {
  const isDesktop = useResponsive("up", "md");
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={4}>
          <Box
            className="flex align-center"
            sx={{ justifyContent: isDesktop ? "end" : "center" }}
          >
            <img
              src={
                "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/CNMAPArtboard+1%404x.png"
              }
              alt="cnmap badge"
              width={"70%"}
            />
          </Box>
        </Grid>
        <Grid item md={8} className="flex align-center">
          <Box>
            <Typography variant="h4">
              Become a Certified Nurse Medical Affairs Professional
            </Typography>
            <Typography>
              All ACMA accredited certifications and certificate programs have
              successfully passed rigorous standards established by the
              International Accreditors for Continuing Education and
              Training/American National Standards Institute (IACET/ANSI).
            </Typography>
            <Rating readOnly defaultValue={4.5} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
