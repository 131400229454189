import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { openInNewTab } from "../../../helpers/openInNewTab";
import { cnmapMarketplace } from "../../../constants/buttons";

export default function Fees() {
  const theme = useTheme();
  return (
    <Container>
      <Box sx={{ mt: 2, mb: 5 }}>
        <Typography
          variant="h3"
          className="text-center"
          sx={{ color: theme.palette.primary.main }}
        >
          Payment Options
        </Typography>
        {/* <Typography className="text-center" variant="subtitle1">
          Discover the path to becoming a Certified Nurse Medical Affairs
          Professional through our comprehensive program.
        </Typography> */}
      </Box>
      <Grid container spacing={2}>
        <Grid item md={4} sx={{ width: "100%" }}>
          <Card>
            <Box
              sx={{
                height: "75px",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                p: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="h4">ACMA Easy Pay Plan</Typography>
            </Box>
            <CardContent>
              <Typography variant="h2" className="text-center">
                $999.99
              </Typography>
              <Typography className="text-center">Program Price</Typography>

              <Box
                sx={{ mt: 3, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>Monthly Installments</Typography>
                <Typography>3</Typography>
              </Box>
              <Box
                sx={{ mt: 3, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>
                  <b>Your Monthly Payment</b>
                </Typography>
                <Typography>
                  <b>$383.33</b>
                </Typography>
              </Box>
              <Typography
                sx={{ mt: 2, fontWeight: "bold", textAlign: "center" }}
              >
                OR
              </Typography>
              <Box
                sx={{ borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>Monthly Installments</Typography>
                <Typography>6</Typography>
              </Box>
              <Box
                sx={{ mt: 3, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>
                  <b>Your Monthly Payment</b>
                </Typography>
                <Typography>
                  <b>$191.66</b>
                </Typography>
              </Box>
              <Box sx={{ mt: 5 }}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={() => openInNewTab(cnmapMarketplace)}
                >
                  Select This Plan
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} sx={{ width: "100%" }}>
          <Card>
            <Box
              sx={{
                height: "75px",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                p: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="h4">⭐One-Time Payment⭐</Typography>
            </Box>
            <CardContent>
              <Typography variant="h2" className="text-center">
                $999.99
              </Typography>

              <Box
                sx={{ mt: 14, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>Application Fee</Typography>
                <Typography>$49.99</Typography>
              </Box>
              <Box
                sx={{ mt: 3, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>CNMAP™ Online Course</Typography>
                <Typography>$800</Typography>
              </Box>
              <Box
                sx={{ mt: 3, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>Credentialing Fee</Typography>
                <Typography>$150</Typography>
              </Box>
              {/* <Box
                sx={{ mt: 3, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>Examination Fee</Typography>
                <Typography>$200</Typography>
              </Box> */}
              <Box sx={{ mt: 5 }}>
                <Button
                  onClick={() => openInNewTab(cnmapMarketplace)}
                  variant="outlined"
                  fullWidth
                  size="large"
                >
                  Select This Plan
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} sx={{ width: "100%" }}>
          <Card>
            <Box
              sx={{
                height: "75px",
                backgroundColor: theme.palette.primary.main,
                color: "white",
                p: 2,
                textAlign: "center",
              }}
            >
              <Typography variant="h4">PayPal CREDIT© Financing</Typography>
            </Box>
            <CardContent>
              <Typography variant="h2" className="text-center">
                $999.99
              </Typography>
              <Box
                sx={{ mt: 11, borderBottom: "dashed 1px" }}
                className="flex justify-between align-center"
              >
                <Typography>
                  PayPal CREDIT© operates within PayPal, using their own terms
                  and conditions.
                </Typography>
                <img
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/paypal.png"
                  alt="Paypal Credit"
                />
              </Box>
              <Box sx={{ marginTop: "88px" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={() =>
                    openInNewTab(
                      "https://www.paypal.com/us/webapps/mpp/paypal-credit"
                    )
                  }
                >
                  Select This Plan
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
