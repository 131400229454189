import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { formatWebinarDate } from "../../../helpers/formatWebinarDate";
import { Link, useNavigate } from "react-router-dom";

export default function UpcomingWebinars({ webinars }) {
  const navigate = useNavigate();
  return (
    <Box>
      <Typography variant="h4">Upcoming Webinars</Typography>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {webinars && webinars.length >= 1 ? (
            <>
              {webinars.map((item, index) => (
                <Grid item md={6} key={index} container>
                  <Card>
                    <Box
                      sx={{
                        height: "50px",
                        backgroundColor: "#023f53",
                        color: "white",
                        pl: 3,
                      }}
                      className="flex align-center"
                    >
                      <CalendarMonthIcon />
                      <Typography sx={{ ml: 1 }}>
                        {formatWebinarDate(item.fields.webinarDate)}
                      </Typography>
                    </Box>
                    <CardActionArea
                      onClick={(e) =>
                        navigate(`/webinar/${item.fields.webinarSlug}`)
                      }
                    >
                      <CardContent>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          <Link
                            to={`/webinar/${item.fields.webinarSlug}`}
                            style={{ color: "black" }}
                          >
                            {item.fields.webinarTitle || ""}
                          </Link>
                        </Typography>

                        <Typography>
                          {documentToReactComponents(
                            item.fields.webinarExcerpt
                          )}
                        </Typography>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "#023f53" }}
                          onClick={() =>
                            window.open(item.fields.webinarLink, "_blank")
                          }
                        >
                          Register for Webinar
                        </Button>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" sx={{ fontWeight: 700 }}>
                      No upcoming webinars
                    </Typography>
                    <Typography>
                      It seems there are currently no upcoming webinars. Please
                      check back later for updates.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
