import { useEffect, useState } from "react";
import { client } from "../helpers/contentfulClient";

// Used to get all entries with a specific content_type_name
//---------------------------------------------------------
function useGetBlogPost(slug) {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    async function fetchData() {
      await client
        .getEntries({
          content_type: "blogPost",
          "fields.blogUrlSlug": slug,
          limit: 1,
        })
        .then((response) => {
          setEntries(response.items[0]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
        });
    }
    fetchData();
  }, [slug]);

  return { entries, loading, error };
}

export default useGetBlogPost;
