import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import Subnav from "../../components/subnav/index";
import EnrollmentCriteria from "../../components/ui/EnrollmentCriteria";
import MaintenanceAndRenewalInfo from "../../components/ui/MaintenanceAndRenewalInfo";
import Questions from "../../components/ui/Questions";
import Footer from "../../components/ui/Footer";
import DocumentPolicy from "../../components/ui/DocumentPolicy";

export default function Enrollment() {
  return (
    <>
      <Helmet>
        <title>Enrollment Info</title>
        <meta
          name="description"
          content="This is a brief summary of my page content."
        />
        <link rel="canonical" href="https://example.com/my-page" />
        <meta property="og:title" content="My Page Title" />
        <meta
          property="og:description"
          content="This is a brief summary of my page content."
        />
        <meta
          property="og:image"
          content="https://example.com/my-page-image.jpg"
        />
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader
            mainTitle={"Enrollment Criteria"}
            image="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/board-requirements.svg"
            learnMoreBtn={"false"}
          />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <Box sx={{ pt: 12, pb: 12, backgroundColor: "white" }}>
        <EnrollmentCriteria />
      </Box>

      <Box sx={{ pt: 12, pb: 12 }}>
        <MaintenanceAndRenewalInfo />
      </Box>

      <Container>
        <Box
          sx={{
            pt: 10,
          }}
        >
          <Questions />
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            pt: 12,
            pb: 12,
          }}
        >
          <DocumentPolicy />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
