import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Link,
  Typography,
} from "@mui/material";
import Avatar from "boring-avatars";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import useGetBlogPost from "../../hooks/useGetBlog";
import Spinner from "../../components/ui/Spinner";
import { formatBlogDate } from "../../helpers/formatBlogDate";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import B2B from "./helpers/B2B";
import B2C from "./helpers/B2C";
import Footer from "../../components/ui/Footer";
import { formatString } from "../../helpers/formatString";
import { Helmet } from "react-helmet-async";

export default function Blog() {
  const navigate = useNavigate();
  let { blogSlug } = useParams();
  const { entries, loading, error } = useGetBlogPost(blogSlug);
  if (loading) {
    return <Spinner />;
  }

  if (error || !entries) {
    return (
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Box>
          <Card>
            <CardContent>
              <Typography variant="h3">Whoops, Blog not found</Typography>
              <Typography>
                Please refresh the page or try another blog
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      sx={{ cursor: "pointer" }}
      onClick={(e) => navigate("/blogs")}
    >
      Blogs
    </Link>,
    <Typography key="3" color="text.primary">
      {formatString(entries.fields.blogTitle, 20)}
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title>{entries.fields.blogTitle}</title>
        <meta name="description" content={entries.fields.blogMetaDescription} />

        <meta property="og:title" content={entries.fields.blogTitle} />
        <meta
          property="og:description"
          content={entries.fields.blogMetaDescription}
        />
        <meta
          property="og:image"
          content={entries.fields.blogImage.fields.file.url}
        />
        <meta
          property="og:url"
          content={`https://nursetopharma.org/blog/${entries.fields.blogUrlSlug}`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={entries.fields.blogTitle} />
        <meta
          name="twitter:description"
          content={entries.fields.blogMetaDescription}
        />
        <meta
          name="twitter:image"
          content={entries.fields.blogImage.fields.file.url}
        />

        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://nursetopharma.org/blog/${entries.fields.blogUrlSlug}"
        },
        "headline": "${entries.fields.blogTitle}",
        "image": {
          "@type": "ImageObject",
          "url": "url-to-your-blog-image"
        },
        "datePublished": "${entries.fields.blogUploadDate}",
        "dateModified": "${entries.fields.blogUploadDate}",
        "author": {
          "@type": "Person",
          "name": "${entries.fields.blogUploadedBy}"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Your Organization",
          "logo": {
            "@type": "ImageObject",
            "url": "url-to-your-organization-logo"
          }
        },
        "description": "${entries.fields.blogMetaDescription}"
      }
    `}
        </script>
      </Helmet>
      <Container maxWidth="lg">
        <Box sx={{ mt: 6 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h3">{entries.fields.blogTitle}</Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Box className="flex align-center">
            <Avatar
              size={60}
              name={entries.fields.blogUploadedBy}
              variant="marble"
              colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
            />
            <Box sx={{ ml: 2 }}>
              <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
                {entries.fields.blogUploadedBy}
              </Typography>
              <Box className="flex align-center">
                <Typography sx={{ fontSize: "14px" }}>
                  {formatBlogDate(entries.fields.blogUploadDate)}
                </Typography>
                <Typography sx={{ ml: 1, mr: 1 }}>|</Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {entries.fields.blogEstimatedReadTime} Minutes Read
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography sx={{ mt: 1 }}>
            {documentToReactComponents(entries.fields.blogContent)}
          </Typography>
        </Box>

        <Box sx={{ mt: 5, mb: 10 }}>
          {entries.fields.blogEndCta &&
          entries.fields.blogEndCta === "B2B CTA" ? (
            <>
              <B2B />
            </>
          ) : (
            <>
              <B2B />
            </>
          )}
        </Box>
      </Container>
      <Footer />
    </>
  );
}
