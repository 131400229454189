import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import useGetWebinarPost from "../../hooks/useGetWebinarPost";
import Spinner from "../../components/ui/Spinner";
import { formatString } from "../../helpers/formatString";
import { Helmet } from "react-helmet-async";

const CustomColoredButton = styled(Button)(({ theme }) => ({
  minWidth: "150px",
  fontSize: "18px",
  borderRadius: "15px",
  fontWeight: "600",
  lineHeight: "30px",
  padding: "14px 24px",
  transition: "color .3s ease,background-color .3s ease,border .3s ease",
  background: "black",

  "&:hover": {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default function Webinar() {
  const navigate = useNavigate();
  let { webinarSlug } = useParams();

  const { entries, loading, error } = useGetWebinarPost(webinarSlug);

  if (loading) {
    return <Spinner />;
  }

  if (error || !entries) {
    return (
      <Container sx={{ mt: 5 }} maxWidth="xl">
        <Box>
          <Card>
            <CardContent>
              <Typography variant="h3">Whoops, Webinar not found</Typography>
              <Typography>
                Please refresh the page or try another webinar
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      sx={{ cursor: "pointer" }}
      onClick={(e) => navigate("/webinars")}
    >
      Webinars
    </Link>,
    <Typography key="3" color="text.primary">
      {formatString(entries.fields.webinarTitle, 50)}
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title>{entries.fields.webinarTitle}</title>
        <meta name="description" content={entries.fields.webinarTitle} />
      </Helmet>

      <Container maxWidth="lg">
        <Box sx={{ mt: 6 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h3">{entries.fields.webinarTitle}</Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          {documentToReactComponents(entries.fields.webinarExcerpt)}
        </Box>

        <Box sx={{ mt: 2 }}>
          {documentToReactComponents(entries.fields.webinarDetails)}
        </Box>
        <Box sx={{ mt: 4, mb: 4 }}>
          <CustomColoredButton
            variant="contained"
            onClick={() => window.open(entries.fields.webinarLink, "_blank")}
          >
            Register for Webinar
          </CustomColoredButton>
        </Box>
      </Container>
    </>
  );
}
