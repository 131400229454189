import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";
import useResponsive from "../../hooks/useResponsive";
import Footer from "../../components/ui/Footer";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 0,
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 0,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive("up", "lg");

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      {!isDesktop ? (
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      ) : (
        ""
      )}

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
