import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import HubspotForm from "react-hubspot-form";

export default function NewsletterSingup() {
  return (
    <Container>
      <Card sx={{ background: "#012E33", color: "white" }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} className="flex justify-center align-center">
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/newsletter.svg"
                alt="newsletter"
              />
            </Grid>
            <Grid item md={6} className="flex justify-center column">
              <Typography variant="h3">Join Our Newsletter</Typography>
              <Typography variant="h5">
                Get weekly access to our best deals, tips for breaking into the
                industry, and current trends.{" "}
              </Typography>
              <Box sx={{ pt: 2 }}>
                <HubspotForm
                  portalId="22711473"
                  formId="a6362bbe-3b26-4a32-850f-74ac2ae2a4d5"
                  onSubmit={() => console.log("Submit!")}
                  onReady={(form) => console.log("Form ready!")}
                  loading={<div>Loading...</div>}
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}
