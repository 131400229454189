import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// utils

import { bgBlur } from "../../../utils/cssStyles";
// components
//
import Logo from "../../../components/logo/Logo";
import CNMAPInfoMenu from "./CNMAPInfoMenu";
import ResourcesMenu from "./ResourcesMenu";
import useResponsive from "../../../hooks/useResponsive";
import { openInNewTab } from "../../../helpers/openInNewTab";
import { cnmapMarketplace } from "../../../constants/buttons";
import BlogButton from "./BlogButton";

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  padding: 0,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const isDesktop = useResponsive("up", "md");
  return (
    <StyledRoot>
      <Container>
        <StyledToolbar disableGutters>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: "text.primary",
              display: { lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Logo />

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {isDesktop ? (
              <>
                <CNMAPInfoMenu />
                <BlogButton />
                {/* <ResourcesMenu /> */}
              </>
            ) : (
              <></>
            )}

            <Button
              variant="contained"
              size="large"
              sx={{ ml: 3 }}
              onClick={() => openInNewTab(cnmapMarketplace)}
            >
              {isDesktop ? <>Get Started Today</> : <>Enroll Today</>}
            </Button>
          </Stack>
        </StyledToolbar>
      </Container>
    </StyledRoot>
  );
}
