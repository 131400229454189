import { Box, Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import ArticleIcon from "@mui/icons-material/Article";
import DuoIcon from "@mui/icons-material/Duo";
import { useNavigate } from "react-router-dom";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

export default function BlogButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBlogs = () => {
    navigate("/blogs");
  };

  const handleWebinars = () => {
    navigate("/webinars");
  };

  const handleScholarships = () => {
    navigate("/scholarships");
  };

  return (
    <Box>
      <Button
        id="fade-button"
        aria-haspopup="true"
        sx={{ mr: 2, fontSize: "15px" }}
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
      >
        Resources
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            left: "1030px",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleBlogs} sx={{ width: "200px" }}>
          <ListItemIcon>
            <ArticleIcon fontSize="small" />
          </ListItemIcon>
          Blogs
        </MenuItem>
        <MenuItem onClick={handleWebinars} sx={{ width: "200px" }}>
          <ListItemIcon>
            <DuoIcon fontSize="small" />
          </ListItemIcon>
          Webinars
        </MenuItem>
        <MenuItem onClick={handleScholarships} sx={{ width: "200px" }}>
          <ListItemIcon>
            <AutoGraphIcon fontSize="small" />
          </ListItemIcon>
          Scholarships
        </MenuItem>
      </Menu>
    </Box>
  );
}
