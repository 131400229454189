import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import Subnav from "../../components/subnav/index";
import Fees from "./helpers/Fees";
import HereToHelp from "../../components/ui/HereToHelp";
import FeeBreakDown from "./helpers/FeeBreakDown";
import Refund from "./helpers/Refund";
import Footer from "../../components/ui/Footer";

export default function Payment() {
  return (
    <>
      <Helmet>
        <title> CNMAP Payment Information </title>
        <meta
          name="description"
          content="Learn more about how you can enhance your nursing career with the Certified Nurse Medical Affairs Professional (CNMAP™) Program. "
        />
        {/* <link rel="canonical" href="https://example.com/my-page" /> */}
        <meta
          property="og:title"
          content="The Certified Nurse Medical Affairs Professional (CNMAP™) Program Information Page"
        />
        <meta
          property="og:description"
          content="The Certified Nurse Medical Affairs Professional (CNMAP™) Program Information Page: Get comprehensive details about the CNMAP™ program, designed to enhance nursing careers. Explore the benefits, curriculum, and flexible learning options of this online certification program in medical affairs and life sciences. Take a step towards advancing your nursing profession with CNMAP™"
        />
        <meta
          property="og:image"
          content="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/CNAMP-Seal-Badge.svg"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader
            mainTitle={"Payment Information"}
            image="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/payment-card.svg"
          />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <Box
        sx={{
          pt: 12,
          pb: 12,
        }}
      >
        <FeeBreakDown />
      </Box>

      <Box
        sx={{
          pt: 12,
          pb: 12,
        }}
      >
        <Fees />
      </Box>
      <Box
        sx={{
          pt: 12,
          pb: 12,
          backgroundColor: "white",
        }}
      >
        <HereToHelp />
      </Box>

      <Box
        sx={{
          pt: 12,
          pb: 12,
        }}
      >
        <Refund />
      </Box>
      <Footer />
    </>
  );
}
