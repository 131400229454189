import { Box, Divider, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function DocumentPolicy() {
  return (
    <Grid container spacing={3}>
      <Grid item md={12}>
        <Typography variant="h3">Documentation & Policies</Typography>
      </Grid>

      <Grid item md={4} sx={{ mt: 3 }}>
        <Typography variant="h4">Program Policy</Typography>
        <Box sx={{ mt: 2 }}>
          <Link to="/cnmap-program-information">
            <Typography>Curriculum Information</Typography>
          </Link>
          <Link to="/cnmap-enrollment-criteria">
            <Typography>Enrollment Criteria</Typography>
          </Link>
          <Link to="/cnmap-recertification">
            <Typography>Maintenance & Renewal</Typography>
          </Link>
          <Link to="/cnmap-promote-certification">
            <Typography>Promote your certification</Typography>
          </Link>
          <Link to="/cnmap-payment">
            <Typography>Payment</Typography>
          </Link>
          <Link to="/cnmap-faqs">
            <Typography>FAQs</Typography>
          </Link>
        </Box>
      </Grid>
      <Grid item md={4} sx={{ mt: 3 }}>
        <Typography variant="h4">Credits Policy</Typography>
        <Box sx={{ mt: 2 }}>
          <Link to="/cnmap-course-credits">
            <Typography>Course Credit Info</Typography>
          </Link>
        </Box>
      </Grid>
      <Grid item md={4} sx={{ mt: 3 }}>
        <Typography variant="h4">General Policy</Typography>
        <Box sx={{ mt: 2 }}>
          <Link to="https://medicalaffairsspecialist.org/policy/terms-and-conditions">
            <Typography>Terms and Conditions</Typography>
          </Link>
          <Link to="https://medicalaffairsspecialist.org/policy/privacy">
            <Typography>Privacy Policy</Typography>
          </Link>
          <Link to="https://medicalaffairsspecialist.org/policy/academic-integrity">
            <Typography>Academic Integrity Policy</Typography>
          </Link>
          <Link to="https://media.medicalaffairsspecialist.org/documents/policy/AntiDiscriminationPolicy2018.pdf?_gl=1*kuj2eg*_ga*MTc4MDg1NTY5NS4xNjcwMjc0NDI2*_ga_KHDPQGKQSS*MTY4NDc2MjkwMi4yNTQuMS4xNjg0NzY0MTU0LjE3LjAuMA..">
            <Typography>Anti Discrimination Policy</Typography>
          </Link>
          <Link to="https://media.medicalaffairsspecialist.org/documents/policy/Instructor-Conflict-of-Interest-Policy.pdf?_gl=1*8m8by7*_ga*MTc4MDg1NTY5NS4xNjcwMjc0NDI2*_ga_KHDPQGKQSS*MTY4NDc2MjkwMi4yNTQuMS4xNjg0NzY0MjA5LjQ0LjAuMA..">
            <Typography>Instructor Conflict of Interest</Typography>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}
