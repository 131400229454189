import { Link } from "react-router-dom";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Grid, Typography } from "@mui/material";
const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-row">
            <div className="footer-col">
              <h4>CNMAP™ Information</h4>
              <ul>
                <li>
                  <Link to="/cnmap-program-information">
                    CNMAP™ Information
                  </Link>
                </li>
                <li>
                  <Link to="/cnmap-enrollment-criteria">
                    Enrollment Criteria
                  </Link>
                </li>
                <li>
                  <Link to="/cnmap-payment">Payment</Link>
                </li>
                <li>
                  <Link to="/cnmap-promote-certification">
                    Certification Promotion
                  </Link>
                </li>

                <li>
                  <Link to="/steering-committee">Steering Committee</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Explore Certifications</h4>
              <ul>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/certifications/bcmas">
                    Board Certified Medical Affairs Speclialist
                  </Link>
                </li>
                <li>
                  <Link to="https://www.priorauthtraining.org/">
                    Prior Authorization Certified Specialist
                  </Link>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/certifications/bcbbs">
                    Board Certified Biologics and Biosimilars Program
                  </Link>
                </li>
                <li>
                  <Link to="https://www.pharmasalestraining.org/">
                    Pharmaceutical Representative Sales Program
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>get help</h4>
              <ul>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/cnmap-faqs">FAQ</Link>
                </li>
                <li>
                  <a href="https://medicalaffairsspecialist.org/about">
                    About ACMA
                  </a>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/policy/terms-and-conditions">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="https://medicalaffairsspecialist.org/policy/terms-and-conditions#refund">
                    Refunds
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer-col">
              <h4>follow us</h4>
              <div>
                <h6 style={{ color: "white" }}>
                  Accreditation Council For Medical Affairs (ACMA)
                </h6>
                <div>
                  <a href="https://www.facebook.com/medicalaffairsspecialist/">
                    <FacebookRoundedIcon className="footer-social-icons" />
                  </a>
                  <a href="https://twitter.com/The_ACMA?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                    <TwitterIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.instagram.com/acmapharma/">
                    <InstagramIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.linkedin.com/company/accreditation-council-for-medical-affairs-acma-/mycompany/verification/">
                    <LinkedInIcon className="footer-social-icons ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={12} className="flex justify-center align-center">
              <Typography
                varaint="h7"
                style={{ color: "#bbbbbb", fontSize: "16px" }}
                className="flex mt-4 text-center"
              >
                Copyright © 2023 Accreditation Council for Medical Affairs. All
                Rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
