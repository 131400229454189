import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import HubspotForm from "react-hubspot-form";
import useResponsive from "../../hooks/useResponsive";
import Footer from "../../components/ui/Footer";

export default function Scholarships() {
  const isDesktop = useResponsive("up", "md");

  const scrollToApply = () => {
    const applySection = document.getElementById("apply");
    if (applySection) {
      applySection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Box
        sx={{
          pt: 20,
          pb: 20,
          color: "white",

          backgroundColor: "#032D3A;",
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23033240' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23023646' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23023b4c' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23023f53' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23014459' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23023f53' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23023b4c' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23023646' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23033240' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23032D3A' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");`,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
        // className="gradient"
      >
        <Container>
          <Grid container spacing={5}>
            <Grid item md={7}>
              <Box className="flex justify-center column">
                <Typography variant="h2">Providing Scholarships</Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  The ACMA is proud to offer a Medical Affairs scholarship
                </Typography>

                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  sx={{
                    mt: isDesktop ? 4 : 2,
                    color: "#032d3a",
                    backgroundColor: "white",
                    width: !isDesktop ? "100%" : "45%",
                    "&:hover": {
                      backgroundColor: "#FFF",
                    },
                  }}
                  size="large"
                  onClick={scrollToApply}
                >
                  Apply for a Scholarship
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          pt: 15,
          pb: 15,
          backgroundColor: "white",
        }}
      >
        <Container>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <Typography variant="h2">
                UIC-ACMA Medical Affairs Annual Scholarship
              </Typography>
              <Typography variant="h6" sx={{ mt: 3 }}>
                The ACMA is proud to offer an annual $25,000 Medical Affairs
                scholarship to graduates of the PharmD program at University of
                Illinois, College of Pharmacy. The scholarship provides an
                opportunity for PharmD graduates to pursue career opportunities
                in the pharmaceutical industry.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <img
                src={
                  "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/acma+life+sciences/uic-acma-scholarship.jpg"
                }
                alt="uic ACMA Scholarship"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          pt: 20,
          pb: 20,
          backgroundColor: "#f1f5f9",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Grid container spacing={5}>
            <Grid item md={7}>
              <Box className="flex justify-center column">
                <Typography variant="h2">Providing Scholarships</Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  The ACMA has assisted with $750,000+ dollars in scholarship
                  money to underprivileged professionals around the world
                </Typography>
                <Typography variant="h1" className="mt-2">
                  $750,000 +
                </Typography>
                <Typography variant="h6">
                  dollars provided in scholarships
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          pt: 15,
          pb: 15,
          backgroundColor: "white",
        }}
      >
        <Container>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <img
                src={
                  "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/acma+life+sciences/Kazikhstan-person-only.jpg"
                }
                alt="Kazikhstan person Scholarship"
              />
            </Grid>

            <Grid item md={6}>
              <Typography variant="h2">
                Meet Saule Bektassova, MD, BCMAS
              </Typography>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Dr. Bektassova lives in Kazakhstan and is one of the many
                recipients of the Medical Affairs Scholarship program from the
                ACMA. The ACMA works with MSL & Medical Affairs professionals in
                over 80+ countries, where we have helped many expand their
                careers, meet quality metrics in the pharmaceutical industry,
                and achieve a standard of excellence through enrolling in the
                BCMAS.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        sx={{
          pt: 20,
          pb: 20,
          backgroundColor: "#f1f5f9",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Grid container spacing={5}>
            <Grid item md={8}>
              <Box className="flex justify-center column">
                <Typography variant="h2">We are with you</Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  The ACMA strives to be a responsible member of our community
                  through our internal operations and service to client
                  organizations. As a part of the healthcare ecosystem, we
                  support policies and practices that produce better outcomes
                  for patients and provide better service to healthcare
                  providers. As a partner to life sciences companies, our goal
                  is to provide the highest quality service and most objective
                  information possible.
                </Typography>

                <div className="mt-5">
                  <Typography variant="h2">
                    Scholarship Program Admission
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Entry into the ACMA Medical Affairs Scholarship Program is
                    highly competitive and applicants are encouraged to review
                    all admission requirements and deadlines prior to beginning
                    the application process.
                  </Typography>
                </div>

                <div className="mt-5">
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    Eligibility Requirements
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    To be eligible for the ACMA Scholarship Program, the
                    candidate must have a minimum of 2 years of relevant
                    professional work experience
                  </Typography>
                </div>

                <div className="mt-5">
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    Income Verification
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    To be eligible for an ACMA academic scholarship, your
                    household federal adjusted gross income must be equivalent
                    to or less than $26,000 per year. For scholarship applicants
                    outside of the United States, adjustments and special
                    considerations may be made based on regional economic
                    conditions as these may vary from country to country
                  </Typography>
                </div>

                <div className="mt-5">
                  <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    For income verification, the following documents may be
                    used:
                  </Typography>{" "}
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="1040 federal or state tax return" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Wages and tax statement (W-2 and/ or 1099, including 1099-MISC, 1099-G, 1099-R, SSA-1099, 1099-DIV, 1099-S, 1099-INT)" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Recent Pay stub within the last 30 days of application date" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Unemployment benefits letter" />
                    </ListItem>
                  </List>
                </div>

                <div className="mt-5">
                  <Typography variant="h2">How to Apply?</Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    To apply for a Patient Access Scholarship, please fill out
                    the form below. You will need to provide the following
                    information:
                  </Typography>

                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Letter of intent (stating what will this scholarship do for you and your career, what are your long-term career aspirations)" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Curriculum Vitae (CV)" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="The email addresses of at least three references" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          style={{ backgroundColor: "white", color: "#032d3a" }}
                        >
                          <ChevronRightIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="A document for income verification" />
                    </ListItem>
                  </List>

                  <Typography variant="h6" sx={{ mt: 2 }}>
                    References may be contacted for further evaluation of the
                    candidate.
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Accepted applicants will be contacted directly by a member
                    from our accreditation team.
                  </Typography>
                </div>

                <div className="mt-5">
                  <Typography variant="h2">Application Deadline</Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Applications are accepted on a rolling basis.
                  </Typography>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    *Please note if you are currently enrolled in the BCMAS
                    program, you are not eligible for a scholarship.
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        id="apply"
        sx={{
          pt: 20,
          pb: 20,
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Typography variant="h2">Apply for a Scholarship</Typography>
          <div className="mt-5">
            <HubspotForm
              portalId="22711473"
              formId="8d268d58-caf2-4745-9f42-4f6554fc24e9"
              onSubmit={() => console.log("Submit!")}
              onReady={(form) => console.log("Form ready!")}
              loading={<div>Loading...</div>}
            />
          </div>
        </Container>
      </Box>

      <Footer />
    </>
  );
}
