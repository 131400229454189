import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Refund() {
  const theme = useTheme();

  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Refund / Cancellation Policy
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Program Access</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Once you submit your application, you will receive immediate
              access to the program learning content. An email with access
              instructions will be sent to your associated email address. You
              will have a total of six months to complete the CNMAP program.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Nonrefundable Application Fee</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Regardless of its action on any application, the ACMA will retain
              a $49.99 USD nonrefundable application fee and will require
              candidates to pay all fees and meet eligibility criteria
              applicable at the time of any future application. Once you have
              been accepted into the program there are no refunds.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Payment via Credit Card</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              For full payment-- Upon submission of application, you will
              automatically be charged $999.99 USD which includes the cost of
              the application fee, CNMAP™ program, and credentialing fee.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Exam Cancellations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Telephone cancellations cannot be accepted for exam scheduling. A
              written notice of cancellation for exam scheduling must be
              received no later than two weeks prior to the examination for a
              refund.
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Program Extensions</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              If any extensions are needed, a charge of $399.99 USD will be
              required, regardless of extension period time frame.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
