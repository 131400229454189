import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useResponsive from "../../hooks/useResponsive";

export default function ModulesCovered() {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const modules = [
    {
      id: 1,
      title: "Introduction to the CNMAP™ Program",
      description:
        "Module 1 provides the fundamental information required to successfully complete the program with tips and tricks of navigating through the program.",
    },
    {
      id: 2,
      title: "Biopharmaceutical Industry",
      description:
        "Module 2 explores the pharmaceutical industry’s structure and operations, the divisions within pharmaceutical companies, and the external factors governing the industry.",
    },
    {
      id: 3,
      title: "Device & Diagnostics Industry",
      description:
        "Module 3 provides an overview of the medical device and the diagnostics industries, product influencers, trends, and regulatory controls. It also outlines the dynamics of these industries and how they differ from other life sciences industries.",
    },
    {
      id: 4,
      title: "Drug Development Process",
      description:
        "Module 4 presents an in-depth explanation of how a compound evolves from the initial discovery stage to the marketplace, detailing the requirements for each phase in the development cycle.",
    },
    {
      id: 5,
      title: "Clinical Trial Design",
      description:
        "Module 5 provides an in-depth understanding of how companies design clinical trials to generate scientifically valid results. It also reviews various clinical trial design types such as parallel studies, double blinded placebo controlled trials, cross sectional studies, meta analyses, systematic reviews, and observational trials.",
    },
    {
      id: 6,
      title: "Compliance",
      description:
        "Module 6 provides pharmaceutical industry compliance rules, regulations, and functions within a pharmaceutical, biotech, medical device, or diagnostics company for both drug and device products.",
    },
    {
      id: 7,
      title: "Regulatory Affairs",
      description:
        "Module 7 is an overview of the regulatory affairs function within life sciences organizations, both domestically and internationally.",
    },
    {
      id: 8,
      title: "Health Economics & Outcomes Research (HEOR)",
      description:
        "Module 8 provides the basic HEOR principles that medical affairs professionals should be familiar with, including the steps involved for assessing pharmacoeconomics and health outcomes studies.",
    },
    {
      id: 9,
      title: "Overview of Medical Information",
      description:
        "Module 9 discusses the typical responsibilities of the medical information team, the competencies necessary to excel in the role of medical information, and the ongoing complexities of the role.",
    },
    {
      id: 10,
      title: "Safety & Pharmacovigilance",
      description:
        "Module 10 outlines the process by which safety signals are evaluated and teaches current steps involved to monitor and assure medication safety and pharmacovigilance. It also covers Phase 4/Post-Marketing studies, and Risk Evaluation and Mitigation Strategies (REMS)",
    },
    {
      id: 11,
      title: "Evidence-Based Medicine",
      description:
        "Module 11 defines the importance and the applicability of evidence based medicine in the modern practice of medicine. You will learn how to interpret various clinical data in the literature and better understand how to critically appraise the medical literature. Most importantly, you will learn how to apply this to your day to day job as a medical affairs professional.",
    },
    {
      id: 12,
      title: "Rules Governing Interactions with HCPs",
      description:
        "Module 12 explains in detail the interactions between medical affairs and healthcare providers, covering the PhRMA code as well as other rules and regulations governing those interactions which benefit the companies, providers, patient populations and the healthcare system in general.",
    },
    {
      id: 13,
      title: "Presentation & Communication Skills",
      description:
        "Module 13 helps develop strong presentation skills specific for a career in the life science industry. Learn how to develop effective presentations for various audiences and how to communicate with several audience types (physicians, internal colleagues and more).",
    },
    {
      id: 14,
      title: "Roles within the Life Sciences industry",
      description:
        "Module 14 provides an overview of the many different opportunities available to nurses in the many components of the life sciences industry - including medical affairs, research and development, commercial, and corporate roles. Learners are exposed to a background, skills required, and a typical day in the life of each role.",
    },
    {
      id: 15,
      title: "Navigating from Bedside to the Life Sciences",
      description:
        "Module 15 dives into the considerations that nurses must have in order to succeed in the life sciences industry and how it differs from traditional bedside skills.",
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Areas Covered in CNMAP™
        </Typography>
        <Typography variant="h6" sx={{ mb: 2 }}>
          What Types of Modules Are in the Program?
        </Typography>
      </Grid>
      {isDesktop ? (
        <>
          <Grid item md={6} className="flex justify-center align-center column">
            <Box sx={{ width: "100%" }}>
              {modules &&
                modules.map((item, index) =>
                  index % 2 === 0 ? (
                    <Box sx={{ mt: 2 }} key={index}>
                      <Accordion
                        expanded={expanded === `panel${item.id}`}
                        onChange={handleChange(`panel${item.id}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          sx={{
                            borderRadius: "15px",
                          }}
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Module {index + 1}
                          </Typography>
                          <Typography sx={{ color: "black" }}>
                            {item.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{item.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ) : (
                    <></>
                  )
                )}
            </Box>
          </Grid>
          <Grid item md={6} className="flex justify-center">
            <Box sx={{ width: "100%" }}>
              {modules &&
                modules.map((item, index) =>
                  index % 2 !== 0 ? (
                    <Box sx={{ mt: 2 }} key={index}>
                      <Accordion
                        expanded={expanded === `panel${item.id}`}
                        onChange={handleChange(`panel${item.id}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          sx={{
                            borderRadius: "15px",
                          }}
                        >
                          <Typography sx={{ width: "33%", flexShrink: 0 }}>
                            Module {index + 1}
                          </Typography>
                          <Typography sx={{ color: "black" }}>
                            {item.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{item.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ) : (
                    <></>
                  )
                )}
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item md={12} className="flex justify-center">
            <Box sx={{ width: "100%" }}>
              {modules &&
                modules.map((item, index) => (
                  <Box sx={{ mt: 2 }} key={index}>
                    <Accordion
                      expanded={expanded === `panel${item.id}`}
                      onChange={handleChange(`panel${item.id}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                          borderRadius: "15px",
                        }}
                      >
                        <Typography sx={{ width: "33%", flexShrink: 0 }}>
                          Module {index + 1}
                        </Typography>
                        <Typography sx={{ color: "black" }}>
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{item.description}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}
