import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
// import { useTheme } from '@mui/material/styles';
import { Link } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

const Logo = forwardRef(() => {
  const isDesktop = useResponsive("up", "md");
  // const theme = useTheme();
  const logoImageUrl =
    "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/acma/logo-blue-dark%403x.png";
  // const logoImageUrl = '';
  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <img
      width={isDesktop ? "200" : "40%"}
      height=""
      src={logoImageUrl}
      alt="arriest logo"
    />
  );

  return (
    <Link to="/home" component={RouterLink} sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

// Logo.propTypes = {
//   // sx: PropTypes.object,
//   // disabledLink: PropTypes.bool,
// };

export default Logo;
