import {
  Container,
  Box,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function RecertificationInfo() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Requirements for renewing your CNMAP™ certification:
        </Typography>
        <Typography sx={{ mt: 3, mb: 1 }} variant="h6">
          The CNMAP™ credential is valid for 5 years. To keep your certification
          active, here is the path:
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Retaking and passing the exam" />
          </ListItem>
          <Typography sx={{ ml: 3 }}>OR</Typography>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Completing 10 hours of continuing education modules provided by the ACMA and successful quiz completion of each module" />
          </ListItem>
        </List>
        <Typography sx={{ mt: 1, mb: 1 }} variant="h6">
          The cost for recertification is $399 USD.
        </Typography>
      </Box>
    </Container>
  );
}
