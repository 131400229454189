import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import Label from "../../../components/label/index";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { formatBlogDate } from "../../../helpers/formatBlogDate";
import { Link, useNavigate } from "react-router-dom";
import { formatString } from "../../../helpers/formatString";

const CustomColoredButton = styled(Button)(({ theme }) => ({
  minWidth: "150px",
  fontSize: "18px",
  borderRadius: "15px",
  fontWeight: "600",
  lineHeight: "30px",
  padding: "14px 24px",
  transition: "color .3s ease,background-color .3s ease,border .3s ease",
  background: "black",

  "&:hover": {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default function FeaturedBlog({ blog }) {
  const navigate = useNavigate();
  return (
    <Box>
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            <Grid item md={4} container>
              <img
                src={blog.fields.blogImage.fields.file.url}
                alt={blog.fields.blogTitle}
                style={{ borderRadius: "16px" }}
                loading="lazy"
              />
            </Grid>
            <Grid item md={8} container>
              <Box>
                {/* <Label>{blog.fields.blogEstimatedReadTime} Minutes Read</Label> */}
                <Box className="flex justify-between align-center">
                  <Label color="success">Featured Blog</Label>
                  <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
                    {formatBlogDate(blog.fields.blogUploadDate)}
                  </Typography>
                </Box>

                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", mt: 2, color: "black" }}
                >
                  <Link
                    to={`/blog/${blog.fields.blogUrlSlug}`}
                    style={{ color: "black" }}
                  >
                    {blog.fields.blogTitle}
                  </Link>
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  {documentToReactComponents(blog.fields.blogExcerpt)}
                </Typography>
                <Box sx={{ mt: 1 }} className="flex align-center">
                  <Typography sx={{ fontWeight: 600 }}>
                    {formatString(blog.fields.blogUploadedBy, 20)}
                  </Typography>
                  <Typography sx={{ ml: 1, mr: 1 }}>|</Typography>
                  <Label>
                    {blog.fields.blogEstimatedReadTime} Minutes Read
                  </Label>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <CustomColoredButton
                    variant="contained"
                    onClick={(e) =>
                      navigate(`/blog/${blog.fields.blogUrlSlug}`)
                    }
                  >
                    Read Featured Blog
                  </CustomColoredButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
