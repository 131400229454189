import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";

export default function WhyCertify({ list, title }) {
  const isDesktop = useResponsive("up", "md");
  return (
    <Box>
      <Container>
        <Typography variant="h2" className="text-center">
          {title}
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ mt: 6 }}
          className="flex justify-center"
        >
          {list &&
            list.length >= 1 &&
            list.map((item, index) => (
              <Grid
                key={index}
                item
                md={3}
                container
                className="card-hover-effect"
              >
                <Card
                  sx={{
                    "&:hover": {
                      color: "#FFF",
                    },
                  }}
                >
                  <CardContent>
                    <Box>
                      <img
                        src={item.image}
                        alt={item.title}
                        width={isDesktop ? "30%" : "20%"}
                      />
                    </Box>
                    <Typography variant="h5" sx={{ mt: 2 }}>
                      {item.title}
                    </Typography>
                    {item.subTitle ? (
                      <>
                        <Typography sx={{ mt: 2 }}>{item.subTitle}</Typography>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
}
