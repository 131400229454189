import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { client } from "../../helpers/contentfulClient";
import { useGetBlogs } from "../../hooks/useGetBlogs";
import Spinner from "../../components/ui/Spinner";
import FeaturedBlog from "./helpers/FeaturedBlog";
import AllBlogs from "./helpers/AllBlogs";
import Footer from "../../components/ui/Footer";

export default function Blogs() {
  const { blogs, loading, error } = useGetBlogs();

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Container sx={{ mt: 5 }}>
        <Box>
          <Card>
            <CardContent>
              <Typography variant="h3">
                Whoops, unable to load CNMAP blogs
              </Typography>
              <Typography>Please refresh the page and try again!</Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  const featuredBlog = blogs.find((blog) => blog.fields.isThisTheFeaturedBlog);
  const remainingBlogs = blogs.filter(
    (blog) => !blog.fields.isThisTheFeaturedBlog
  );
  const selectedBlog = featuredBlog || remainingBlogs[0];

  return (
    <>
      <Container>
        <Box sx={{ mt: 3 }}>
          <FeaturedBlog blog={selectedBlog} />
        </Box>

        <Box sx={{ mt: 5 }}>
          <AllBlogs blogs={remainingBlogs} />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
