import { Box, Button, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { openInNewTab } from "../../helpers/openInNewTab";
import { useNavigate } from "react-router-dom";
export default function Questions() {
  const navigate = useNavigate();
  return (
    <Box>
      <Typography variant="h4">
        Have questions about CNMAP™? Speak to an ACMA Team Member.{" "}
      </Typography>
      <Button
        sx={{ mt: 3 }}
        variant="outlined"
        endIcon={<SendIcon />}
        onClick={() => navigate("/contact-us")}
      >
        {" "}
        Contact Us Today
      </Button>
    </Box>
  );
}
