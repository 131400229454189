export function formatBlogDate(inputDate) {
  try {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  } catch (error) {
    console.error("Error formatting date:", error);
    return inputDate; // Return the original string in case of an error
  }
}
