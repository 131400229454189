import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { formatWebinarDate } from "../../../helpers/formatWebinarDate";
import { useNavigate } from "react-router-dom";

export default function PastWebinars({ pastWebinars }) {
  return (
    <Box>
      <Typography variant="h4">Past Webinars</Typography>

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          {pastWebinars && pastWebinars.length >= 1 ? (
            <>
              {pastWebinars.map((item, index) => (
                <Grid item md={6} key={index} container>
                  <Card>
                    <Box
                      sx={{
                        height: "50px",
                        backgroundColor: "#023f53",
                        color: "white",
                        pl: 3,
                      }}
                      className="flex align-center"
                    >
                      <CalendarMonthIcon />
                      <Typography sx={{ ml: 1 }}>
                        {formatWebinarDate(item.fields.webinarDate)}
                      </Typography>
                    </Box>
                    <CardContent>
                      {item.fields.webinarRecordingLink ? (
                        <>
                          <iframe
                            width="515"
                            height="300"
                            src={`${item.fields.webinarRecordingLink}`}
                            frameBorder="0"
                            allowFullScreen
                            style={{ borderRadius: "15px" }}
                            title="Embedded youtube"
                          />
                        </>
                      ) : (
                        <>
                          <Card
                            sx={{
                              width: "515px",
                              height: "300px",
                              borderRadius: "15px",
                              backgroundColor: "#023f53",
                            }}
                            className="flex justify-center align-center"
                          >
                            <CardContent>
                              <Typography variant="h4" className="color-white">
                                Recording video will be available soon{" "}
                              </Typography>
                            </CardContent>
                          </Card>
                        </>
                      )}

                      <Typography variant="h6" sx={{ fontWeight: 600, mt: 2 }}>
                        {item.fields.webinarTitle || ""}
                      </Typography>

                      <Typography>
                        {documentToReactComponents(item.fields.webinarExcerpt)}
                      </Typography>

                      {item.fields.webinarSlidedeckLink ? (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              window.open(
                                item.fields.webinarSlidedeckLink,
                                "_blank"
                              )
                            }
                          >
                            Download Webinar Slidedeck
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" sx={{ fontWeight: 700 }}>
                      No past webinars found
                    </Typography>
                    <Typography>
                      It seems there are currently no past webinars found.
                      Please check back later for updates.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
