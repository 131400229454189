import { Box, Container, Typography, useTheme } from "@mui/material";

export default function MaintenanceAndRenewalInfo() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Maintenance & Renewal of Certification
        </Typography>

        <Typography sx={{ lineHeight: "2", mt: 3, mb: 2 }} variant="h6">
          The CNMAP™ Certification must be renewed every 5 years.
        </Typography>
        <Typography sx={{ lineHeight: "2" }}>
          The cost for recertification is $399 USD. For more questions about
          recertification, please email us at{" "}
          <a href="mailto:info@acmainfo.org">info@acmainfo.org</a>.
        </Typography>

        <Typography
          sx={{ mt: 3, mb: 2, color: theme.palette.primary.main }}
          variant="h6"
        >
          There are two paths for maintaining & renewing certification:
        </Typography>
        <Typography sx={{ lineHeight: "2" }}>
          Retaking and passing the exam
        </Typography>
        <Typography
          sx={{ mt: 3, mb: 2, color: theme.palette.primary.main }}
          variant="h6"
        >
          Or
        </Typography>
        <Typography sx={{ lineHeight: "2" }}>
          Completing 10 hours of continuing education provided by the ACMA
        </Typography>
      </Box>
    </Container>
  );
}
