import { Container, Box, Typography, useTheme } from "@mui/material";

export default function ProgramStructure() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          Program Structure
        </Typography>
        <Typography sx={{ mt: 3, mb: 3 }} variant="h6">
          Each of the 15 CNMAP™ modules includes:
        </Typography>
        <Typography variant="h6">1 . Pre-Quiz (Optional)</Typography>
        <Typography>
          Establishes your existing knowledge of the specific module's content
        </Typography>

        <Typography sx={{ mt: 3 }} variant="h6">
          2 . Module Content
        </Typography>
        <Typography>
          Consists of multiple sections that vary from module to module{" "}
        </Typography>

        <Typography sx={{ mt: 3 }} variant="h6">
          3 . Post-Quiz
        </Typography>
        <Typography>
          Determines how well you’ve absorbed and retained the module content
        </Typography>
      </Box>

      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6">Time needed to complete</Typography>
        <Typography>
          The CNMAP™ is a self-paced program, and you have six months of access
          to complete the certification. On average, most learners complete the
          program within 1-2 months (approximately 20 hours). You will be given
          1 month to complete the program.
        </Typography>
      </Box>
    </Container>
  );
}
