import { Helmet } from "react-helmet-async";
import Footer from "../../components/ui/Footer";
import { Box, Container } from "@mui/material";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import WhatIsCNMAP from "./helpers/WhatIsCNMAP";
import Subnav from "../../components/subnav/index";
import Infographics from "../../components/infographics";
import programNumbers from "../../components/infographics/config/programNumbers";
import ModulesCovered from "../../components/modules/ModulesCovered";
import ProgramStructure from "../../components/ui/ProgramStructure";
import DocumentPolicy from "../../components/ui/DocumentPolicy";

export default function ProgramInfo() {
  return (
    <>
      <Helmet>
        <title> CNMAP Program Information </title>
        <meta
          name="description"
          content="Learn more about how you can enhance your nursing career with the Certified Nurse Medical Affairs Professional (CNMAP™) Program. "
        />
        {/* <link rel="canonical" href="https://example.com/my-page" /> */}
        <meta
          property="og:title"
          content="The Certified Nurse Medical Affairs Professional (CNMAP™) Program Information Page"
        />
        <meta
          property="og:description"
          content="The Certified Nurse Medical Affairs Professional (CNMAP™) Program Information Page: Get comprehensive details about the CNMAP™ program, designed to enhance nursing careers. Explore the benefits, curriculum, and flexible learning options of this online certification program in medical affairs and life sciences. Take a step towards advancing your nursing profession with CNMAP™"
        />
        <meta
          property="og:image"
          content="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/CNAMP-Seal-Badge.svg"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader mainTitle={"Program Info"} learnMoreBtn={"false"} />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <Box sx={{ pt: 12, pb: 12, backgroundColor: "white" }}>
        <WhatIsCNMAP />
      </Box>

      <Box sx={{ pt: 12, pb: 12 }}>
        <Infographics config={programNumbers} title={"CNMAP™ By the Numbers"} />
      </Box>
      <Container>
        <Box sx={{ pt: 12, pb: 12 }}>
          <ModulesCovered />
        </Box>
      </Container>

      <Box sx={{ pt: 12, pb: 12, backgroundColor: "white" }}>
        <ProgramStructure />
      </Box>
      <Container>
        <Box
          sx={{
            pt: 12,
            pb: 12,
          }}
        >
          <DocumentPolicy />
        </Box>
      </Container>

      <Footer />
    </>
  );
}
