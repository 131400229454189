import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import Subnav from "../../components/subnav/index";

import Footer from "../../components/ui/Footer";
import FAQsQuestions from "./helpers/FAQsQuestions";

export default function FAQs() {
  return (
    <>
      <Helmet>
        <title>FAQs</title>
        <meta
          name="description"
          content="This is a brief summary of my page content."
        />
        <link rel="canonical" href="https://example.com/my-page" />
        <meta property="og:title" content="My Page Title" />
        <meta
          property="og:description"
          content="This is a brief summary of my page content."
        />
        <meta
          property="og:image"
          content="https://example.com/my-page-image.jpg"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": "My Blog Post Title",
        "description": "This is a brief summary of my blog post content.",
        "image": "https://example.com/my-blog-post-image.jpg",
        "author": {
          "@type": "Person",
          "name": "John Doe"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Example Company",
          "logo": {
            "@type": "ImageObject",
            "url": "https://example.com/logo.jpg"
          }
        },
        "datePublished": "2023-05-01T12:00:00Z",
        "dateModified": "2023-05-01T12:00:00Z"
      }
    `}
        </script>
        <meta name="robots" content="index, follow" />
        <script
          type="text/javascript"
          data-cfasync="false"
        >{`window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } }; (function () { var done = false; var script = document.createElement('script'); script.async = true; script.type = 'text/javascript'; script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; document.getElementsByTagName('HEAD').item(0).appendChild(script); script.onreadystatechange = script.onload = function (e) { if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { var w = new PCWidget({c: '9ebe7140-f8dd-461f-8166-bc15f0f62aca', f: true }); done = true; } }; })();`}</script>
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader
            mainTitle={"FAQs"}
            image="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/FAQs.svg"
            width={"70%"}
          />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <Box
        sx={{
          pt: 12,
          pb: 12,
        }}
      >
        <FAQsQuestions />
      </Box>

      <Footer />
    </>
  );
}
