import PropTypes from "prop-types";
import { Link, NavLink as RouterLink, useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Grid,
  List,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
//
// import { StyledNavItem, StyledNavItemIcon } from "./styles";

// ----------------------------------------------------------------------

NavSectionMobile.propTypes = {
  data: PropTypes.array,
};

export default function NavSectionMobile({ data = [], ...other }) {
  return (
    <>
      <Typography variant="h2" className="text-center" sx={{ mb: 2 }}>
        Quick Links
      </Typography>
      <Grid container spacing={3}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </Grid>
    </>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };
  return (
    <Grid item xs={6} container sx={{ width: "100%" }}>
      <Card
        sx={{
          width: "100%",
        }}
      >
        <CardActionArea onClick={() => handleClick(path)}>
          <CardContent sx={{ width: "100%", padding: "17px" }}>
            {icon}

            <Typography sx={{ color: theme.palette.primary.main }}>
              <Link
                to={path}
                underline="none"
                style={{ color: theme.palette.primary.main }}
              >
                {title}
              </Link>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
