const RecertBenefits = [
  {
    title: "Continuing Education",
    subTitle:
      "Continuing education helps you stay relevant as the profession evolves and as the industry grows",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/growth.png",
  },
  {
    title: "Commitment to Your Profession",
    subTitle: "Demonstrated commitment to your profession",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/increase.svg",
  },
  {
    title: "Graduate Network",
    subTitle: "Allows for participation in the graduate network",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/graduationCap.svg",
  },
  {
    title: "Salary Increase",
    subTitle: "Potential for salary increases and performance enhancements",
    image:
      "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/earnings.png",
  },
];

export default RecertBenefits;
