import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useResponsive from "../../../hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { cnmapMarketplace } from "../../../constants/buttons";
import { openInNewTab } from "../../../helpers/openInNewTab";
import SendIcon from "@mui/icons-material/Send";

export default function Header({ onClick }) {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={5}>
        <Grid item md={7}>
          <Box className="flex justify-center column">
            <Typography variant="h2">
              Are you a nurse looking for a way to apply your clinical
              experience in the life sciences industry?
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Become a Certified Nurse Medical Affairs Professional with CNMAP™
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">➤ Fast track your career</Typography>
              <Typography variant="h6" sx={{ mt: 1 }}>
                ➤ Join a rapidly growing field and impact patients on another
                level
              </Typography>
              <Typography sx={{ mt: 1 }} variant="h6">
                ➤ More work from home opportunities with greater stability and
                quality of life
              </Typography>
              <Typography sx={{ mt: 1 }} variant="h6">
                ➤ Grow your earning potential
              </Typography>
            </Box>
            {/* <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Fast track your career" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Join a rapidly growing field and impact patients on another level" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="More work from home opportunities with greater stability and quality of life" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Grow your earning potential" />
              </ListItem>
            </List> */}
          </Box>

          <Box sx={{ mt: 3 }}>
            <Button
              variant="outlined"
              size="large"
              sx={{
                color: "white",
                border: "solid 1px white",
                width: !isDesktop ? "100%" : "45%",
              }}
              onClick={onClick}
            >
              Learn More About CNMAP™
            </Button>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              sx={{
                ml: isDesktop ? 2 : 0,
                mt: isDesktop ? 0 : 2,
                color: "#032d3a",
                backgroundColor: "white",
                width: !isDesktop ? "100%" : "45%",
                "&:hover": {
                  backgroundColor: "#FFF",
                },
              }}
              size="large"
              onClick={() => openInNewTab(cnmapMarketplace)}
            >
              Get Started Today
            </Button>
          </Box>
        </Grid>
        {isDesktop ? (
          <>
            <Grid item md={5} className="flex justify-center align-center">
              <Box className="flex justify-center align-center">
                <img
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medical+info/CNMAP-transitions-nurse-to-pharma.svg"
                  alt="nurse-to-pharma"
                  loading="lazy"
                />
              </Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      {/* <Box sx={{ mt: 8 }}>
        <Typography>
          <b>
            The life sciences industry is comprised of companies that research
            and develop pharmaceutical products, medical devices, diagnostics
            and other health technologies. Nurses' clinical knowledge can be
            very valuable to these companies. Distinguish yourself with the only
            accredited nursing certification program for the life science
            industry - CNMAP™
          </b>
        </Typography>
      </Box> */}
    </>
  );
}
