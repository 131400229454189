import {
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function RecertificationInfoSteps() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          CNMAP™ Recertification
        </Typography>

        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="To begin your recertification, log into the ACMA Marketplace" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Find your way to the product catalog and search for CNMAP™ Recertification" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Choose either the CNMAP™ modules option or exam option" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Continue to payment" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Login to the ACMA learning portal and begin the recertification" />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
}
