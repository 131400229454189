import { Navigate, useRoutes } from "react-router-dom";
// layouts
import SimpleLayout from "./layouts/simple";
import MainLayout from "./layouts/main";
// pages
import Page404 from "./pages/Page404";
import Welcome from "./pages/welcome";
import ProgramInfo from "./pages/programInfo";
import Enrollment from "./pages/enrollmentCriteria";
import Recertification from "./pages/recertification";
import Promote from "./pages/promote";
import Payment from "./pages/payment";
import FAQs from "./pages/FAQs";
import ContactUs from "./pages/contactUs";
import CourseCredits from "./pages/credits";
import SteeringCommittee from "./pages/steering";
import Blogs from "./pages/blogs";
import Blog from "./pages/blog";
import Webinars from "./pages/webinars";
import Webinar from "./pages/webinar";
import Scholarships from "./pages/scholarships";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        { path: "/home", element: <Welcome /> },
        { path: "/webinars", element: <Webinars /> },
        { path: "/webinar/:webinarSlug", element: <Webinar /> },
        { path: "/blogs", element: <Blogs /> },
        { path: "/blog/:blogSlug", element: <Blog /> },
        { path: "/cnmap-program-information", element: <ProgramInfo /> },
        { path: "/cnmap-enrollment-criteria", element: <Enrollment /> },
        { path: "/cnmap-recertification", element: <Recertification /> },
        { path: "/cnmap-promote-certification", element: <Promote /> },
        { path: "/cnmap-payment", element: <Payment /> },
        { path: "/cnmap-faqs", element: <FAQs /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "/cnmap-course-credits", element: <CourseCredits /> },
        { path: "/steering-committee", element: <SteeringCommittee /> },
        { path: "/scholarships", element: <Scholarships /> },
      ],
    },

    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        // { path: "/login", element: <SignIn /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
