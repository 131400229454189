import { Box, Container } from "@mui/material";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import Subnav from "../../components/subnav/index";
import Committee from "./helpers/Committee";
import Footer from "../../components/ui/Footer";

export default function SteeringCommittee() {
  return (
    <>
      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader
            mainTitle={"Steering Committee"}
            description={
              "The Industry Experts Behind CNMAP™ - The CNMAP™ program was put together by industry and subject matter experts from across the industry.  The CNMAP™ program is the first of its kind program to give nurses the tools they need to break into the life sciences industry."
            }
            width={"70%"}
          />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <Box sx={{ pt: 10, pb: 10 }}>
        <Committee />
      </Box>

      <Footer />
    </>
  );
}
