import {
  Avatar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function CNMAPprogram() {
  return (
    <Container>
      <Grid container spacing={3} sx={{}}>
        <Grid item md={6}>
          <Typography variant="h2">
            What is the Certified Nurse Medical Affairs Professional (CNMAP™)
            Program ?
          </Typography>
          <Typography variant="h6" sx={{ mt: 3 }}>
            Nurses are critical thinkers and agile. They have all of the valued
            skills the life sciences industry demands. CNMAP™ establishes the
            pathway into life sciences roles for nurses and advance practice
            nurses.
          </Typography>
          <Typography variant="h6" sx={{ mt: 3 }}>
            CNMAP™ is the first of its kind program to establish the industry
            standard for nurse professionals seeking entry into the industry, in
            various roles.
          </Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                  <ChevronRightIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Format - 100% Online, Self-Paced" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                  <ChevronRightIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Time Needed to Complete - 15-20 hours" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                  <ChevronRightIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Exam Required - Yes (online)" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                  <ChevronRightIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Support Provided - 24/7 Live Chat Included" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                  <ChevronRightIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Availability: Year Round" />
            </ListItem>
          </List>
        </Grid>
        <Grid item md={6}>
          <img
            src={
              "https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/marie-rivera-img.svg"
            }
            alt="transition to pharma"
          />
        </Grid>
      </Grid>
    </Container>
  );
}
