import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Container,
  useTheme,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function FeeBreakDown() {
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid
          item
          md={6}
          className="justify-center"
          sx={{ width: "100%", mt: 2 }}
        >
          <Card sx={{ width: isDesktop ? "80%" : "100%" }}>
            <CardContent>
              <Box className="flex justify-between align-center" sx={{ mt: 3 }}>
                <Typography>Application Fee</Typography>
                <Typography>$49.99</Typography>
              </Box>
              <Box className="flex justify-between align-center" sx={{ mt: 3 }}>
                <Typography>CNMAP™ Online Course</Typography>
                <Typography>$800</Typography>
              </Box>
              {/* <Box className="flex justify-between align-center" sx={{ mt: 3 }}>
                <Typography>Examination Fee</Typography>
                <Typography>$200</Typography>
              </Box> */}
              <Box
                className="flex justify-between align-center"
                sx={{ mt: 3, borderBottom: "dashed 1px" }}
              >
                <Typography>Credentialing Fee</Typography>
                <Typography>$150</Typography>
              </Box>
              <Box className="flex justify-between align-center" sx={{ mt: 3 }}>
                <Typography>Total</Typography>
                <Typography>$999.99</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Box sx={{ mt: 3 }}>
            <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
              Top Features
            </Typography>
            <Box>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="15 Comprehensive Modules with Knowledge Checks" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Problem Based Case Studies" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Unlimited 24/7 Live Chat Support" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="6 Month Unlimited Access" />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
