import {
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import WhyCertify from "../../../components/ui/WhyCertify";
import ContentList from "../../../components/ui/WhyCertify/ContentList";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function WhatIsCNMAP() {
  const theme = useTheme();
  return (
    <Container>
      <Box>
        <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
          More About CNMAP™{" "}
        </Typography>
        <Typography sx={{ mt: 3, mb: 3 }} variant="h6">
          What is CNMAP™?
        </Typography>
        <Typography sx={{ lineHeight: "2" }}>
          The Certified Nurse Medical Affairs Program (CNMAP™) is a self-paced,
          online, certification program that provides nurses and advanced degree
          nurses the opportunity to educate themselves in the areas of medical
          affairs and life sciences, and therefore, creating a new avenue to
          career development. The CNMAP™ program enhances understanding of the
          medical affairs landscape within the pharmaceutical industry, allowing
          nursing professionals to power and advance their career in this
          rapidly expanding field.
        </Typography>
        <Typography sx={{ pt: 3, lineHeight: "2" }}>
          This comprehensive, 15-module, self-paced, e-learning, online program
          trains nursing professionals on the most critical knowledge areas
          within the medical affairs space to establish oneself as a subject
          matter expert in the field. With the CNMAP™ certification, graduates
          are able to prove their commitment to raising the bar in medical
          affairs and exhibit their leg up on the competition when searching for
          different career pathways.
        </Typography>
        <Typography sx={{ mt: 3, mb: 3 }} variant="h6">
          Are you a nurse looking for a way to apply your clinical experience in
          the life sciences* industry?
        </Typography>
        <Typography sx={{ lineHeight: "2" }}>
          Become a Certified Nurse Medical Affairs Professional with CNMAP™
        </Typography>
        <Box
          className="flex  align-center"
          sx={{ pl: 2, mt: 2, lineHeight: "2" }}
        >
          <ArrowCircleRightIcon />
          <Typography sx={{ ml: 3 }}>Fast track your career</Typography>
        </Box>
        <Box
          className="flex  align-center"
          sx={{ pl: 2, mt: 2, lineHeight: "2" }}
        >
          <ArrowCircleRightIcon />
          <Typography sx={{ ml: 3 }}>
            Join a rapidly growing field and impact patients on another level
          </Typography>
        </Box>
        <Box
          className="flex  align-center"
          sx={{ pl: 2, mt: 2, lineHeight: "2" }}
        >
          <ArrowCircleRightIcon />
          <Typography sx={{ ml: 3 }}>
            More work environment flexibility and quality of life
          </Typography>
        </Box>
        <Box
          className="flex  align-center"
          sx={{ pl: 2, mt: 2, lineHeight: "2" }}
        >
          <ArrowCircleRightIcon />
          <Typography sx={{ ml: 3 }}>Grow your earning potential</Typography>
        </Box>
        <Typography sx={{ mt: 3 }}>
          Distinguish yourself with the only accredited nursing certification
          program for the life science industry - CNMAP™
        </Typography>{" "}
        <Typography sx={{ mt: 3 }}>
          Nurses who want career advancement, new ways to impact patients, more
          flexible work environment options and competitive salaries may want to
          consider moving to the life science industry.
        </Typography>
        <Typography sx={{ mt: 3, mb: 2, lineHeight: "2" }} variant="h6">
          <b>CNMAP™ Eligibility Criteria</b>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          ACMA developed the CNMAP™ Certification program to educate nurses on
          the life sciences industry, to help nursing professionals gain a
          deeper understanding of the pharmaceutical field, to give them new
          options when refocusing their careers.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          In order to be certified, you must be one of the following:
        </Typography>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Nurse or advance practice nurse
"
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Graduate of relevant degree from an accredited college, university, or institution" />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                <ChevronRightIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="RN, BSN, MSN, DNP, etc" />
          </ListItem>
        </List>
        {/* <Box sx={{ pt: 15, pb: 15 }}>
          <WhyCertify
            list={ContentList}
            title={
              "Why become a Certified Nurse Medical Affairs Professional (CNMAP™)?"
            }
          />
        </Box> */}
      </Box>
    </Container>
  );
}
