import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function HereToHelp() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <Box className="flex justify-center column" sx={{ mt: 3 }}>
            <Typography variant="h3" sx={{ color: theme.palette.primary.main }}>
              We're Here to Help
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Questions?
            </Typography>
            <Typography variant="subtitle1">
              Chat with us or call (855) 255 - 7137
            </Typography>
          </Box>

          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </Button>
        </Grid>
        <Grid item md={6} className="flex justify-center align-center">
          <img
            src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/CNMAP/here-to-help.svg"
            loading="lazy"
            alt="here to help"
            width={"70%"}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
